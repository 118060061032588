import React from 'react';

function HomePage() {
   return (
       <div>
           <h1>Welcome to the Home Page</h1>
           <p>This is the home page of our application.</p>
       </div>
   );
}

export default HomePage;