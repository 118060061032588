import React from 'react';

const GrandTotalDisplay = ({ totalRows }) => {
	const cpsSum = (totalRows.reduce((sum, row) => sum + Number(row[8]), 0)).toFixed(2);
	const cpsAdjSum = (totalRows.reduce((sum, row) => sum + Number(row[9]), 0)).toFixed(2);
	const cpsTotalSum = (totalRows.reduce((sum, row) => sum + Number(row[10]), 0)).toFixed(2);
	const dareSum = (totalRows.reduce((sum, row) => sum + Number(row[11]), 0)).toFixed(2);
	const dareAdjSum = (totalRows.reduce((sum, row) => sum + Number(row[12]), 0)).toFixed(2);
	const dareTotalSum = (totalRows.reduce((sum, row) => sum + Number(row[13]), 0)).toFixed(2);
	const subTotalSum = (totalRows.reduce((sum, row) => sum + Number(row[14]), 0)).toFixed(2);
	const adjTotalSum = (totalRows.reduce((sum, row) => sum + Number(row[15]), 0)).toFixed(2);
	const totalTaxSum = (totalRows.reduce((sum, row) => sum + Number(row[16]), 0)).toFixed(2);

	return (
		<div className='grandtotal-wrapper'>
			<h2>Grand Total</h2>
			<table>
				<thead>
					<tr>
						<th>CPS</th>
						<th>CPS Adj.</th>
						<th>CPS Total</th>
						<th>DARE</th>
						<th>DARE Adj.</th>
						<th>DARE Total</th>
						<th>Sub Total</th>
						<th>Adj. Totals</th>
						<th>Total Tax</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{cpsSum}</td>
						<td>{cpsAdjSum}</td>
						<td>{cpsTotalSum}</td>
						<td>{dareSum}</td>
						<td>{dareAdjSum}</td>
						<td>{dareTotalSum}</td>
						<td>{subTotalSum}</td>
						<td>{adjTotalSum}</td>
						<td>{totalTaxSum}</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default GrandTotalDisplay
