import React, { useState, useRef } from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import * as XLSX from 'xlsx';
import '../../style/TaxesByAddress.css';
import JustDataTab from './tabs/JustDataTab';
import ByStatesTab from './tabs/ByStatesTab';
import HotTab from './tabs/HotTab';

function TaxByAddress() {
	const fileInputRef = useRef();
	const [file, setFile] = useState(null);
	const [fileName, setFileName] = useState('');
	const [tab, setTab] = useState(0);
	const [processedData, setProcessedData] = useState([]);

	const handleTabChange = (event, newValue) => {
		setTab(newValue);
	};

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (!file) {
			alert('Please upload a file first.');
			return;
		}

		setFile(file);
		setFileName(file.name);

		const reader = new FileReader();
		reader.onload = (evt) => {
			const bstr = evt.target.result;
			const wb = XLSX.read(bstr, { type: 'binary' });
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];

			const jsonData = XLSX.utils.sheet_to_json(ws, { range: 11 });

			let currentState = null;
			let processedRecords = [];

			jsonData.forEach(record => {
				if (record['State (from Address)']) {
					currentState = record['State (from Address)'];
				}

				let cps = 0;
				let dare = 0;

				if (record['DOCUMENT CODE'] && record['DOCUMENT CODE'].split('-')[record['DOCUMENT CODE'].split('-').length - 1].length === 6) {
					dare = parseFloat(record['TAX AMOUNT']);
				} else if (record['DOCUMENT CODE'] && record['DOCUMENT CODE'].split('-')[record['DOCUMENT CODE'].split('-').length - 1].length === 5) {
					cps = parseFloat(record['TAX AMOUNT']);
				}

				if (record['DOCUMENT CODE'] && !record['DOCUMENT CODE'].startsWith('Total of')) {
					processedRecords.push({
						totalState: currentState,
						documentCode: record['DOCUMENT CODE'],
						documentDate: record['DOCUMENT DATE'],
						totalSales: parseFloat(record['TOTAL SALES']),
						discount: parseFloat(record['DISCOUNT']),
						totalSalesLessDiscounts: parseFloat(record['TOTAL SALES LESS DISCOUNTS']),
						totalExemptNonTaxableSales: parseFloat(record['TOTAL EXEMPT/ NON TAXABLE SALES']),
						totalTaxableSales: parseFloat(record['TOTAL TAXABLE SALES']),
						cps: cps,
						dare: dare,
					});
				}
			});

			setProcessedData(processedRecords);
		};
		reader.readAsBinaryString(file);
	};

	const StyledInput = styled('input')({
		display: 'none',
	});

	const FileButton = ({ children }) => (
		<label>
			<StyledInput ref={fileInputRef} id="file-upload" type="file" onChange={handleFileChange} />
			<Button variant="contained" style={{ color: 'white' }} onClick={() => fileInputRef.current.click()}>{children}</Button>
			{fileName && <span style={{ marginLeft: '10px', color: 'black' }}>{fileName}</span>}
		</label>
	);


	return (
		<div className="taxes-by-address">
			<h1>Taxes By Address</h1>

			<div className="controls">
				<div className="title-and-browse">
					<FileButton>Browse for file</FileButton>
				</div>
			</div>

			{processedData.length > 0 && (
				<>
					<Tabs value={tab} onChange={handleTabChange}>
						<Tab label="HotTab" />
						<Tab label="JustDataTab" />
						<Tab label="ByStatesTab" />

					</Tabs>
					{tab === 0 && (
						<HotTab excelData={processedData} />
					)}
					{tab === 1 && (
						<JustDataTab processedData={processedData} />
					)}
					{tab === 2 && (
						<ByStatesTab processedData={processedData} />
					)}
				</>
			)}
		</div >
	);
}

export default TaxByAddress;
