// dependencyMappingsAndFormulas.js

export const COLUMN_INDEX = {
	STATE: 0,
	DOCUMENT_CODE: 1,
	DOCUMENT_DATE: 2,
	TOTAL_SALES: 3,
	DISCOUNT: 4,
	TOTAL_SALES_LESS_DISCOUNT: 5,
	TOTAL_EXEMPT_NON_TAXABLE_SALES: 6,
	TOTAL_TAXABLE_SALES: 7,
	CPS: 8,
	CPS_ADJ: 9,
	CPS_TOTAL: 10,
	DARE: 11,
	DARE_ADJ: 12,
	DARE_TOTAL: 13,
	SUB_TOTALS: 14,
	ADJ_TOTALS: 15,
	TOTAL_TAX: 16,


};
