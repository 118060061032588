// client/src/components/pages/tabs/HotTab/dataProcessing.js

export function processRows(state, records, setTotalRows) {
	const rows = [];

	const addTotalsRow = () => {

		const totalSales = records.reduce((total, record) => total + (Number(record.totalSales) || 0), 0);
		const discount = records.reduce((total, record) => total + (Number(record.discount) || 0), 0);
		const totalSalesLessDiscounts = records.reduce((total, record) => total + (Number(record.totalSalesLessDiscounts) || 0), 0);
		const totalExemptNonTaxableSales = records.reduce((total, record) => total + (Number(record.totalExemptNonTaxableSales) || 0), 0);
		const totalTaxableSales = records.reduce((total, record) => total + (Number(record.totalTaxableSales) || 0), 0);


		const cpsTotal = records.reduce((total, record) => total + (Number(record.cps) || 0), 0);
		const cpsAdjTotal = records.reduce((total, record) => total + (Number(record.cpsAdj) || 0), 0);

		const dareTotal = records.reduce((total, record) => total + (Number(record.dare) || 0), 0);
		const dareAdjTotal = records.reduce((total, record) => total + (Number(record.dareAdj) || 0), 0);

		const subTotal = cpsTotal + dareTotal;
		const adjTotals = cpsAdjTotal + dareAdjTotal;
		const totalTax = subTotal + adjTotals;

		let totalRow = [
			`${state}-Totals`,
			null,
			null,
			totalSales.toFixed(2) || null,
			discount.toFixed(2) || null,
			totalSalesLessDiscounts.toFixed(2) || null,
			totalExemptNonTaxableSales.toFixed(2) || null,
			totalTaxableSales.toFixed(2) || null,
			cpsTotal.toFixed(2) || null,
			cpsAdjTotal.toFixed(2) || null,
			(cpsTotal + cpsAdjTotal).toFixed(2) || null,
			dareTotal.toFixed(2) || null,
			dareAdjTotal.toFixed(2) || null,
			(dareTotal + dareAdjTotal).toFixed(2) || null,
			subTotal.toFixed(2) || null,
			adjTotals.toFixed(2) || null,
			totalTax.toFixed(2) || null
		];

		totalRow.forEach((item, index) => {
			if (item === '0.00') {
				totalRow[index] = '';
			}
		});

		rows.unshift(totalRow);
		setTotalRows(prevTotalRows => [...prevTotalRows, totalRow]);

	};

	records.forEach(record => {
		const cpsTotal = Number(record.cps) + (Number(record.cpsAdj) || null);
		const dareTotal = Number(record.dare) + (Number(record.dareAdj) || null);
		const subTotal = cpsTotal + dareTotal;
		const totalTax = subTotal;

		const formattedRecord = [
			state,
			record.documentCode,
			record.documentDate,
			(record.totalSales || 0).toString(),
			(record.discount || 0).toString(),
			(record.totalSalesLessDiscounts || 0).toString(),
			(record.totalExemptNonTaxableSales || 0).toString(),
			(record.totalTaxableSales || 0).toString(),
			(record.cps || 0).toString(),
			(record.cpsAdj || 0).toString(),
			(cpsTotal || 0).toString(),
			(record.dare || 0).toString(),
			(record.dareAdj || 0).toString(),
			(dareTotal || 0).toString(),
			(subTotal || 0).toString(),
			(record.adjTotals || 0).toString(),
			(totalTax || 0).toString()
		];

		formattedRecord.forEach((item, index) => {
			if (item === '0') {
				formattedRecord[index] = '';
			}
		});

		rows.push(formattedRecord);

	});

	addTotalsRow(); 

	return rows;
};
