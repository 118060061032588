import React, { useEffect, useState, useRef } from 'react';
import Handsontable from 'handsontable';
import 'handsontable/dist/handsontable.full.css';
import HyperFormula from 'hyperformula';
import { processRows } from './HotTab/dataProcessing';
import { COLUMN_INDEX } from './HotTab/dependencyMappingsAndFormulas';
import { hideNonTotalRows } from './HotTab/classSettingFunctions';
import GrandTotalDisplay from './HotTab/GrandTotalDisplay';
import Button from '@mui/material/Button';

const HotTab = ({ excelData }) => {
	const hotTableComponent = useRef(null);
	const hotTable = useRef(null);

	const newHeaders = ["CPS", "CPS Adj.", "CPS Total", "DARE", "DARE Adj.", "DARE Total", "Sub Totals", "Adj. Totals", "Total Tax"];
	const headers = ["State", "Document Code", "Document Date", "Total Sales", "Discount", "Total Sales Less Discount", "Total Exempt/Non Taxable Sales", "Total Taxable Sales", ...newHeaders];

	const [showIndividualRecords, setShowIndividualRecords] = useState(false);
	const [processedRows, setProcessedRows] = useState();
	const [renderData, setRenderData] = useState();

	const [totalRows, setTotalRows] = useState([]);

	const toggleRecordsVisibility = () => {
		setShowIndividualRecords(!showIndividualRecords);
		const updatedData = hideNonTotalRows(processedRows, !showIndividualRecords);
		console.log('Updated data:', updatedData);

		setRenderData(updatedData);
	};

	useEffect(() => {
		if (hotTable.current) {
			hotTable.current.loadData(renderData);
		}
	}, [renderData]);

	useEffect(() => {
		if (hotTable.current) {
			return;
		}

	}, [renderData]);

	useEffect(() => {
		if (hotTable.current) {
			return;
		}

		const groupedData = excelData.reduce((accumulator, record) => {
			if (!accumulator[record.totalState]) {
				accumulator[record.totalState] = [];
			}
			accumulator[record.totalState].push(record);

			return accumulator;
		}, {});

		const processedData = Object.entries(groupedData).flatMap(([state, records]) => {
			return processRows(state, records, setTotalRows);
		});

		setProcessedRows(processedData);

		const filteredData = hideNonTotalRows(processedData, false);
		console.log('Filtered data:', filteredData);

		setRenderData(filteredData);

		const cssSafeHeaders = headers.map(header => header.replace(/ /g, '-').replace(/\./g, '').toLowerCase());

		const emptyStringRenderer = function (instance, td, row, col, prop, value, cellProperties) {
			Handsontable.renderers.TextRenderer.apply(this, arguments);
			if (value === 0) {
				td.innerHTML = '';
			}
		};

		const hotSettings = {
			data: renderData,
			licenseKey: 'non-commercial-and-evaluation',
			colHeaders: headers,
			manualColumnResize: true,
			rowHeaders: false,
			autoColumnSize: true,
			formulas: {
				engine: HyperFormula,
				dataProvider: {
					getData: () => renderData,
					getDataLength: () => renderData.length,
					getColumnHeader: (index) => index + 1,
					getRowHeader: (index) => index + 1,
					getValue: (row, column) => renderData[row][column],
					getLastRowIndex: () => renderData.length - 1,
					getLastColumnIndex: () => renderData[0].length - 1,
				},
				formulaParser: {
					parse: (expression) => expression,
					unparse: (parsedExpression) => parsedExpression,
				},
			},
			afterRender: () => { },
			beforeGetColHeader: (col, TH) => {
				const className = cssSafeHeaders[col];
				if (className) {
					TH.className += className;
				}
			},
			currentRowClassName: 'currentRow',
			beforeGetCellMeta: (row, col, cellProperties) => {
				if (hotTable.current) {
					const rowData = hotTable.current.getDataAtRow(row);
					if (rowData && rowData[0] && rowData[0].endsWith('-Totals')) {
						cellProperties.className = 'state-totals ';
					}
					else {
						cellProperties.className = 'not-totals ';
					}
				}
				const className = cssSafeHeaders[col];
				if (className) {
					cellProperties.className += " " + className + " ";
				}
			},
			validator: (value, callback) => {
				if (/^-?\d+(\.\d+)?$/.test(value) || /^\.\d+$/.test(value) || value === '') {
					callback(true);
				} else {
					callback(false);
				}
			},
			allowInvalid: false,
			cells: function (row, col, prop) {
				var cellProperties = {};

				var value = this.instance.getDataAtRowProp(row, prop);
				if (value === null || value === undefined || value === '' || value === 0) {
					cellProperties.renderer = emptyStringRenderer;
				}

				if (col !== COLUMN_INDEX.ADJ_TOTALS) {
					cellProperties.readOnly = true;
				}

				return cellProperties;
			},
			afterChange: (changes, source) => {
				if (changes) {
					changes.forEach(([row, col, oldValue, newValue]) => {

						if (col === COLUMN_INDEX.ADJ_TOTALS) {
							const cpsValue = parseFloat(hotTable.current.getDataAtCell(row, COLUMN_INDEX.CPS)) * 100;
							const dareValue = parseFloat(hotTable.current.getDataAtCell(row, COLUMN_INDEX.DARE)) * 100;

							const subTotalValue = hotTable.current.getDataAtCell(row, COLUMN_INDEX.SUB_TOTALS);
							const adjTotalValue = hotTable.current.getDataAtCell(row, COLUMN_INDEX.ADJ_TOTALS);
							const subTotal = subTotalValue ? parseFloat(subTotalValue) * 100 : 0;
							const adjTotal = adjTotalValue ? parseFloat(adjTotalValue) * 100 : 0;

							const totalTax = subTotal + adjTotal;

							hotTable.current.setDataAtCell(row, COLUMN_INDEX.TOTAL_TAX, totalTax ? (totalTax / 100).toFixed(2) : '');

							if (dareValue > cpsValue) {
								hotTable.current.setDataAtCell(row, COLUMN_INDEX.DARE_ADJ, adjTotal ? (adjTotal / 100).toFixed(2) : '');
							} else {
								hotTable.current.setDataAtCell(row, COLUMN_INDEX.CPS_ADJ, adjTotal ? (adjTotal / 100).toFixed(2) : '');
							}
						}

						if (col === COLUMN_INDEX.CPS_ADJ) {
							const cpsValue = parseFloat(hotTable.current.getDataAtCell(row, COLUMN_INDEX.CPS)) * 100;
							const adjValue = parseFloat(hotTable.current.getDataAtCell(row, COLUMN_INDEX.CPS_ADJ)) * 100;
							const cpsNum = cpsValue ? cpsValue : 0;
							const adjNum = adjValue ? adjValue : 0;
							const newCpsTotal = cpsNum + adjNum;

							hotTable.current.setDataAtCell(row, COLUMN_INDEX.CPS_TOTAL, newCpsTotal ? (newCpsTotal / 100).toFixed(2) : '');
						}

						if (col === COLUMN_INDEX.DARE_ADJ) {
							const dareValue = parseFloat(hotTable.current.getDataAtCell(row, COLUMN_INDEX.DARE)) * 100;
							const adjValue = parseFloat(hotTable.current.getDataAtCell(row, COLUMN_INDEX.DARE_ADJ)) * 100;
							const dareNum = dareValue ? dareValue : 0;
							const adjNum = adjValue ? adjValue : 0;
							const newDareTotal = dareNum + adjNum;

							hotTable.current.setDataAtCell(row, COLUMN_INDEX.DARE_TOTAL, newDareTotal ? (newDareTotal / 100).toFixed(2) : '');
						}

						const updatedRecord = hotTable.current.getDataAtRow(row);
						console.log('Updated Record:', updatedRecord);

						const matchingRecordIndex = totalRows.findIndex(totalRow => totalRow[0] === updatedRecord[0]);

						if (matchingRecordIndex !== -1) {
							console.log('Matching Record in totalRows:', totalRows[matchingRecordIndex]);
						}

						setTotalRows(prevTotalRows => {
							const newTotalRows = [...prevTotalRows];
							newTotalRows[matchingRecordIndex] = updatedRecord;
							return newTotalRows;
						});

						console.log('Updated Record in totalRows:', totalRows[matchingRecordIndex]);
					});
				}
			},
		};

		hotTable.current = new Handsontable(hotTableComponent.current, hotSettings);

		return () => {
			if (hotTable.current) {
				hotTable.current.destroy();
			}
		};
	}, [excelData]);

	return (
		<div>

			<GrandTotalDisplay totalRows={totalRows} />

			<div className="table-wrapper">
				<Button variant="contained" style={{ color: 'white' }} onClick={() => toggleRecordsVisibility()}>Hide/Show Records</Button>
				<div ref={hotTableComponent} />
			</div>
		</div>
	);
};
export default HotTab;
