import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import TaxByAddress from './pages/TaxesByAddress';
import '../style/Navigation.css'

import NavigationBar from './NavigationBar';

function Navigation() {
	return (
		<Router>
			<div>
				<nav className="navigation">
					<NavigationBar />
				</nav>

				<Routes>
					<Route path="/taxes-by-address" element={<TaxByAddress />} />
					<Route path="/" element={<HomePage />} />
				</Routes>
			</div>
		</Router>
	);
}

export default Navigation;
