import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';

const ByStatesTab = ({ processedData }) => {
	const [groupedRecords, setGroupedRecords] = useState([]);
	const [visibleStates, setVisibleStates] = useState({});
	const [totals, setTotals] = useState({});

	useEffect(() => {
		let totalValues = {};
		let groupedRecords = [];
		let currentRecords = [];
		let currentState = null;

		processedData.forEach(record => {
			if (currentState !== record.totalState) {
				if (currentState !== null) {
					groupedRecords.push({ state: currentState, records: currentRecords });
				}
				currentState = record.totalState;
				currentRecords = [];
			}
			currentRecords.push(record);

			if (!totalValues[currentState]) {
				totalValues[currentState] = {
					totalSales: 0,
					discount: 0,
					totalSalesLessDiscounts: 0,
					totalExemptNonTaxableSales: 0,
					totalTaxableSales: 0,
					taxAmount: 0,
					cps: 0,
					dare: 0
				};
			}
			totalValues[currentState].totalSales += record.totalSales;
			totalValues[currentState].discount += record.discount;
			totalValues[currentState].totalSalesLessDiscounts += record.totalSalesLessDiscounts;
			totalValues[currentState].totalExemptNonTaxableSales += record.totalExemptNonTaxableSales;
			totalValues[currentState].totalTaxableSales += record.totalTaxableSales;
			totalValues[currentState].taxAmount += record.taxAmount;
			totalValues[currentState].cps += record.cps;
			totalValues[currentState].dare += record.dare;
		});

		if (currentState !== null) {
			groupedRecords.push({ state: currentState, records: currentRecords });
		}

		setGroupedRecords(groupedRecords);
		setTotals(totalValues);
	}, [processedData]);

	const toggleStateVisibility = (state) => {
		setVisibleStates(prevVisibleStates => ({ ...prevVisibleStates, [state]: !prevVisibleStates[state] }));
	};

	const showAll = () => {
		const allStates = groupedRecords.reduce((acc, group) => ({ ...acc, [group.state]: true }), {});
		setVisibleStates(allStates);
	};

	const hideAll = () => {
		const allStates = groupedRecords.reduce((acc, group) => ({ ...acc, [group.state]: false }), {});
		setVisibleStates(allStates);
	};

	let formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});

	return (
		<div>
			<Button onClick={showAll}>Show All</Button>
			<Button onClick={hideAll}>Hide All</Button>
			<div>
				{groupedRecords.map((group, i) => {
					if (!group || !group.records) return null;
					return (
						<div key={i}>
							<div className="state-header">
								<Button
									variant="contained"
									style={{ backgroundColor: "lightGrey", color: 'black' }}
									onClick={() => toggleStateVisibility(group.state)}>
									{visibleStates[group.state] ? '▲' : '▼'}
								</Button>
								<h2>{group.state}</h2>
							</div>
							<table>
								<thead>
									<tr>
										<th>Document Code</th>
										<th>Document Date</th>
										<th>Total Sales</th>
										<th>Discount</th>
										<th>Total Sales Less Discounts</th>
										<th>Total Exempt/Non Taxable Sales</th>
										<th>Total Taxable Sales</th>
										<th>Tax Amount</th>
										<th>CPS</th>
										<th>DARE</th>
									</tr>
								</thead>
								<tbody>
									{visibleStates[group.state] && group.records.map((record, j) => {
										let docCodeParts = record.documentCode ? record.documentCode.split('-') : [];
										let lastPart = docCodeParts.length > 0 ? docCodeParts[docCodeParts.length - 1] : '';
										let dare = '';
										let cps = '';

										if (lastPart.length === 6) {
											dare = record.taxAmount;
										} else if (lastPart.length === 5) {
											cps = record.taxAmount;
										}

										return (
											<tr key={j}>
												<td>{record.documentCode}</td>
												<td>{record.documentDate}</td>
												<td>{formatter.format(record.totalSales)}</td>
												<td>{formatter.format(record.discount)}</td>
												<td>{formatter.format(record.totalSalesLessDiscounts)}</td>
												<td>{formatter.format(record.totalExemptNonTaxableSales)}</td>
												<td>{formatter.format(record.totalTaxableSales)}</td>
												<td>{formatter.format(record.taxAmount)}</td>
												<td>{cps ? formatter.format(cps) : ''}</td>
												<td>{dare ? formatter.format(dare) : ''}</td>
											</tr>
										)
									})}
									<tr className="total-row">
										<td colSpan="2">Total of ({group.state})</td>
										<td>{formatter.format(totals[group.state].totalSales)}</td>
										<td>{formatter.format(totals[group.state].discount)}</td>
										<td>{formatter.format(totals[group.state].totalSalesLessDiscounts)}</td>
										<td>{formatter.format(totals[group.state].totalExemptNonTaxableSales)}</td>
										<td>{formatter.format(totals[group.state].totalTaxableSales)}</td>
										<td>{formatter.format(totals[group.state].taxAmount)}</td>
										<td>{formatter.format(totals[group.state].cps)}</td>
										<td>{formatter.format(totals[group.state].dare)}</td>
									</tr>
								</tbody>
							</table>
						</div>
					);
				})}
			</div>
		</div>
	)
}

export default ByStatesTab
