import React from 'react'

const JustDataTab = ({ processedData }) => {
	return (
		<div>
			<table>
				<thead>
					<tr>
						<th>State</th>
						<th>Document Code</th>
						<th>Total Sales</th>
						<th>Discount</th>
						<th>Total Sales Less Discounts</th>
						<th>Total Exempt/Non Taxable Sales</th>
						<th>Total Taxable Sales</th>
						<th>Tax Amount</th>
						<th>CPS</th>
						<th>DARE</th>
					</tr>
				</thead>
				<tbody>
					{processedData.map((record, i) => (
						<tr key={i}>
							<td>{record.totalState}</td>
							<td>{record.documentCode}</td>
							<td>{record.totalSales}</td>
							<td>{record.discount}</td>
							<td>{record.totalSalesLessDiscounts}</td>
							<td>{record.totalExemptNonTaxableSales}</td>
							<td>{record.totalTaxableSales}</td>
							<td>{record.taxAmount}</td>
							<td>{record.cps}</td>
							<td>{record.dare}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

export default JustDataTab