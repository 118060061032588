import React from 'react';
import { NavLink } from 'react-router-dom';

const NavigationBar = () => {
	return (
		<nav className="navbar">
			<ul className="navbar-links">
				<li><NavLink to="/" className="nav-link">Home</NavLink></li>
				<li><NavLink to="/taxes-by-address" className="nav-link" >Taxes By Address</NavLink></li>
			</ul>
		</nav>
	);
};

export default NavigationBar;
